
<ul class="sublist" *ngIf="menuList.length>0">

  <ng-template ngFor let-submenu [ngForOf]="menuList" let-j="index">
    <li appExpandmenu *ngIf="submenu.children && submenu.children.length>0">
      <a *ngIf="submenu.link_id !=0" (click)="cs.openmenu(submenu.slug)">{{submenu.link_name}} <i
           *ngIf="submenu.children && submenu.children.length>0"
          aria-hidden="true"></i></a>
      <a *ngIf="submenu.link_id==0 && submenu.custom_link !='#'" [href]="submenu.custom_link"
        target="_blank">{{submenu.link_name}} <i
          *ngIf="submenu.children && submenu.children.length>0" aria-hidden="true"></i></a>
      <a *ngIf="submenu.link_id==0 && submenu.custom_link =='#'">{{submenu.link_name}} <i
          class="" *ngIf="submenu.children && submenu.children.length>0"
          aria-hidden="true"></i></a>
    </li>
    <li  *ngIf="(submenu.children && submenu.children.length==0) || !submenu.children">
      <a *ngIf="submenu.link_id !=0" (click)="cs.openmenu(submenu.slug)">{{submenu.link_name}} <i
           *ngIf="submenu.children && submenu.children.length>0"
          aria-hidden="true"></i></a>
      <a *ngIf="submenu.link_id==0 && submenu.custom_link !='#'" [href]="submenu.custom_link"
        target="_blank">{{submenu.link_name}} <i
          *ngIf="submenu.children && submenu.children.length>0" aria-hidden="true"></i></a>
      <a *ngIf="submenu.link_id==0 && submenu.custom_link =='#'">{{submenu.link_name}} <i
          class="" *ngIf="submenu.children && submenu.children.length>0"
          aria-hidden="true"></i></a>
    </li>
    <li *ngIf="submenu.children && submenu.children.length>0">

      <app-nav-menu class="navsub" [menuList]="submenu.children" ></app-nav-menu>
    </li>
  </ng-template>
</ul>
