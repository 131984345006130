import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router'

@Directive({
  selector: '[appRouterDirective]'
})
export class RouterDirective{

  constructor(private el: ElementRef, private router: Router) { }

  @HostListener('click', ['$event.target']) onClick($event){
      console.info('clicked: ' + $event.getAttribute('data-link'));
      var goRoute = $event.getAttribute('data-link');
      this.router.navigate([goRoute]);

  }
}
