declare let gtag: Function;
export class GoogleAnalyticsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue
    })

  }
  public addeventEmitter(
    eventName: string,
    eventData: any,) {
    gtag('event', eventName, eventData)

  }
}
