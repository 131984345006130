import { CommonService } from '../../utils/common.service';
import { Component, OnInit,ViewChild,ElementRef,Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {ReCaptcha2Component} from 'ngx-captcha';
import { Router,ActivatedRoute } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PixelService } from 'ngx-pixel';

@Component({
  selector: 'app-admissionenquiry',
  templateUrl: './admissionenquiry.component.html',
  styleUrls: ['./admissionenquiry.component.css']
})
export class AdmissionenquiryComponent implements OnInit {
  public form_validation="";
  upatern=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/;
  public sitekey=environment.ggglcy;
  public form_success="";
  public submitted=false;
  public page_blocks=[];
  public page_slider=[];
  public page_data:any={};
  public slug:any='';
  public months =[1,2,3,4,5,6,7,8,9,10,11,12];
  public years =[2021,2022,2023,2024,2025,2026,2027,2028,2029,2030];
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('mcontactForm', { static: true }) mcontactForm: ElementRef;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  admissionForm = new FormGroup({
    first_name: new FormControl('',  [Validators.required,Validators.maxLength(50)]),
    last_name: new FormControl('',  [Validators.required,Validators.maxLength(50)]),
    user_email: new FormControl('', [Validators.required,	Validators.pattern(this.upatern)]),
    enquiry_type: new FormControl('1'),
    contact_number: new FormControl(''),
    nationality:  new FormControl(''),
    child1_dob:  new FormControl(''),
    child2_dob:  new FormControl(''),
    child3_dob:  new FormControl(''),
    child4_dob:  new FormControl(''),
    expected_start_month:  new FormControl(''),
    expected_start_year:  new FormControl(''),
    admission_message:new FormControl('', Validators.required),
    recaptcha: new FormControl('', Validators.required)

  });
  // numberOnly(event): boolean {
  //   var charCode = (event.which) ? event.which : event.keyCode
  //   if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || (charCode==187)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  numberOnly(evt):boolean
{
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}
  constructor(
    private pixel: PixelService,
    public router:Router,public cs: CommonService,private route: ActivatedRoute) {

  }
  resetvalues(){
    this.admissionForm.patchValue({first_name: '',last_name: '', user_email: '', contact_number: '',nationality:'',child1_dob:'',child2_dob:'',child3_dob:'',child4_dob:'',expected_start_month:'',expected_start_year:'', admission_message: '', recaptcha: '',enquiry_type:'1' });
     this.submitted=false;
    this.captchaElem.resetCaptcha();
  }
  public onsubmit(form){
    this.submitted=true;
    if(form.valid){
      this.form_validation="";
      if(this.admissionForm.value.enquiry_type=='1'){
        this.pixel.track('Lead', { content_category: 'enquiry',content_name:"General Enquiry"  });
        this.cs.trigger_googleevent("enquiry", "Lead","submit","General Enquiry", 1);
      }else{
        this.pixel.track('Lead', { content_category: 'enquiry',content_name:"Admission Enquiry"  });
        this.cs.trigger_googleevent("enquiry", "Lead","submit","Admission Enquiry",  1);
      }

      this.cs.postData({ sourceid: 'cms/admissionenquiry', info: this.admissionForm.value })
      .subscribe((data: any) => {
       //console.log(JSON.stringify(data));
        if (data.status == 1) {
          this.resetvalues();
          this.form_success = "Thank you for contacting us. We will get back to you shortly."
        }else{
          this.form_validation="Some Error Occured";
        }
      })
    }else{
      this.form_validation = 'Fill out Mandatory Fields';
    }


  }

  handlePageChange(data:any){
    this.page_data=data;
   }
  get_blocks_slider(slug){

    this.cs
    .postData({
      sourceid: 'list/calldbproc',
      info: { db: 'cms', procname: 'web_list_blocks_sliders', vals: [slug] },
    })  .subscribe((data) => {
      if (data['status'] == 1) {
        this.slug=slug;
        this.page_blocks = data.data['0'];
        this.page_slider = data.data['1'];
        for (var i in this.page_blocks) {
          try {
            this[
              this.page_blocks[i]['block_id']
            ].nativeElement.innerHTML = this.page_blocks[i][
              'block_content'
            ];
          } catch (error) {}
        }
      }else{
        this.slug=slug;
      }
    })
  }
  ngOnInit() {
   // this.get_blocks_slider('admission-enquiry');

  }

}
