import { Component, OnInit,Input,Output,EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { TagsService } from '../../utils/tags.service';
import { CommonService } from '../../utils/common.service';
import { isPlatformBrowser } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import { NavigationStart, NavigationCancel, Router } from '@angular/router';
declare let $, jQuery,window;
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-seomodule',
  templateUrl: './seomodule.component.html',
  styleUrls: ['./seomodule.component.css']
})
export class SeomoduleComponent implements OnInit {
  @Output() pageDataChange: EventEmitter<any> = new EventEmitter();
  public content: any = { page_description: '' };
  public faqs = [];
  public faqs_schema: any = {};
  public relevant_pdfs = [];
  public relevant_articles: any = [];
  public data_loaded=0;
  public canonicalLink=""
  @Input()  slug:any = '';
  @Input() disablefaqs:any='0';
  constructor(    public tagservice: TagsService,
    public router:Router,public cs: CommonService,@Inject(PLATFORM_ID) private platformId: Object,public  doms:DomSanitizer
   ) { }

  ngOnInit(): void {
   this.canonicalLink=  environment.domain.slice(0, -1)+""+this.router.url ;
    this.getdata(this.slug);
  }
  getdata(slug) {
    //console.log(slug+"////////////");
    this.cs
      .postData({
        sourceid: 'list/calldbproc',
        info: { db: 'cms', procname: 'page_details', vals: [slug] },
      })
      .subscribe((data) => {
         // console.log

        if (data['status'] == 1) {
          this.data_loaded = 1;
          if (data.data['0'].length > 0) {
            this.content = data.data['0']['0'];
            if( this.content.page_description !=''){
              this.content.page_description = this.doms.bypassSecurityTrustHtml( this.content.page_description);
            }
            this.pageDataChange.emit({page_name:  this.content['page_name'],page_caption:this.content['page_caption']})
           // console.log(this.content);
            //===for video and pdf resources===////
            if (this.content['video_media_ids'] != '') {
              try {
                this.content['video_media_ids'] = this.content[
                  'video_media_ids'
                ]
                  .toString()
                  .split(',');
              } catch (error) {
                this.content['video_media_ids'] = [];
              }
            } else {
              this.content['video_media_ids'] = [];
            }

            this.relevant_pdfs = data.data['3'];

            //===for video and pdf resources===////
            this.faqs = data.data['2'];
            //this.relevant_articles= data.data['3'];

            //console.log(JSON.stringify( this.content ));
            var metadata = {
              title: this.content['seo_title'],
              description: this.content['seo_description'],
              // imageurl:
              //   this.cs.apiUrl + 'media/' + this.content['sm_banner_filename'],
              // image_alt: this.content['page_sm_banner_alt_text'],
              // //  image_alt: this.accreditation_details['alt_text'],
              // videourl:
              //   this.content['video_media_ids']['0'] != ''
              //     ? 'https://www.youtube.com/watch?v=' +
              //       this.content['video_media_ids']['0']
              //     : '',
              page_type: 'website',
              url: '',
              //    author_name: this.course_details['faculty_name'],

            };
            this.tagservice.updatetags(metadata);
            if (this.faqs.length > 0) {
              this.tagservice.generate_faqs(this.faqs).subscribe((data) => {
                this.faqs_schema = data;
                this.triggerquery();
              });
            }
            if (typeof window !== 'undefined') {
              // try {
              // window.gtag('set', 'page', this.router.url.toString());
              // } catch (e) {

              // }

              }
          } else {
            this.router.navigate(["/error/404"]);
          }
        } else {
          //this.getdata('home')
        }
        // console.log(JSON.stringify(data));
      });
  }
  triggerquery() {
    if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          $('.accordion')
            .find('.accordion-title')
            .on('click', function () {
              // Adds Active Class
              $(this).toggleClass('active');
              // Expand or Collapse This Panel
              $(this).next().slideToggle('fast');
              // Hide The Other Panels
              $('.accordion-content').not($(this).next()).slideUp('fast');
              // Removes Active Class From Other Titles
              $('.accordion-title').not($(this)).removeClass('active');
            });
        }, 300);
    }
  }
}
