<div class="modal-header " >
  <h2 class="modal-title pull-left enquiry-header">Enquiry</h2>
  <!-- /(click)="modalRef.hide()" -->
  <button type="button" class="close pull-right" aria-label="Close"  (click)="this.closeModal.emit()" >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

    <form [formGroup]="admissionForm" (ngSubmit)="onsubmit(admissionForm)" #madmissionForm="ngForm">
      <div class="row">
        <div class="col-lg-12 col-md-8 col-sm-12 col-12">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group required">
                <label for="name"> First Name</label>
                <input type="text" class="form-control" formControlName="first_name" placeholder="" name="first_name"
                  required maxlength="50">
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.first_name.errors?.required">Your First Name is
                  required</span>
              </div>
            </div>
            <div class="col-lg-6 col-12">

              <div class="form-group required">
                <label for="name"> Last Name</label>
                <input type="text" class="form-control" formControlName="last_name" placeholder="" name="last_name"
                  required maxlength="50">
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.last_name.errors?.required">Your Last Name is
                  required</span>
              </div>
            </div>

            <div class="col-lg-6 col-12 ">
              <div class="form-group required">
                <label for="name"> Email ID</label>
                <input type="text" class="form-control" formControlName="user_email" placeholder="" name="user_email"
                  required maxlength="100">
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.user_email.errors?.required">Your Email ID is
                  required</span>
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.user_email.errors?.pattern">Please enter a valid Email
                  Address</span>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group required">
                <label for="name">Phone Number</label>
                <input type="text" class="form-control" (keypress)="numberOnly($event)" formControlName="contact_number"
                  placeholder="" name="contact_number" required maxlength="17">
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.contact_number.errors?.required">Your Phone Number is
                  required</span>
              </div>

            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group ">
                <label for="name">Enquiry Type</label>
                <select name="enquirytype" formControlName="enquiry_type" class="form-control">
                  <option value="1">General Enquiry</option>
                  <option value="2">Admission Enquiry</option>
                </select>

              </div>
            </div>
            <ng-template [ngIf]="admissionForm.value.enquiry_type =='2'">
              <div class="col-lg-6 col-12">
                <div class="form-group required">
                  <label for="name">Nationality</label>
                  <input type="text" class="form-control" formControlName="nationality" name="nationality" required
                    maxlength="15">
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.nationality.errors?.required">Your Nationality is
                    required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group required">
                  <label for="name">Child 1 - Date of Birth</label>
                  <input type="text" required class="form-control" readonly bsDatepicker formControlName="child1_dob"
                    name="child1_dob">
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.child1_dob.errors?.required">Your
                    Child 1 - Date of Birth is required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label for="name">Child 2 - Date of Birth</label>
                  <input type="text" class="form-control" readonly bsDatepicker formControlName="child2_dob"
                    name="child2_dob">
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.child2_dob.errors?.required">Your
                    Child 2 - Date of Birth is required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label for="name">Child 3 - Date of Birth</label>
                  <input type="text" class="form-control" readonly bsDatepicker formControlName="child3_dob"
                    name="child3_dob">
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.child3_dob.errors?.required">Your
                    Child 3 - Date of Birth is required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label for="name">Child 4 - Date of Birth</label>
                  <input type="text" class="form-control" readonly bsDatepicker formControlName="child4_dob"
                    name="child4_dob">
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.child4_dob.errors?.required">Your
                    Child 4 - Date of Birth is required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label for="name">Expected Start Month </label>
                  <!-- <input type="text" class="form-control" formControlName="expected_start_month"
                    name="expected_start_month" required> -->
                   <select class="form-control"  formControlName="expected_start_month">
                     <option value="0">Click to Select</option>
                      <option *ngFor="let item of months" [value]="item">{{item}}
                      </option>
                    </select>
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.expected_start_month.errors?.required">Your Expected
                    Start
                    month required</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="form-group">
                  <label for="name">Expected Start Year</label>
                  <select class="form-control"  formControlName="expected_start_year">
                    <option value="0">Click to Select</option>
                     <option *ngFor="let item of years" [value]="item">{{item}}
                     </option>
                   </select>
                  <!-- <input type="text" class="form-control" (keypress)="numberOnly($event)"
                    formControlName="expected_start_year" name="expected_start_year" required maxlength="5"> -->
                  <span class="help-block text-danger"
                    *ngIf="submitted && admissionForm.controls.expected_start_year.errors?.required">Your Expected
                    Start Year
                    required</span>
                </div>
              </div>
            </ng-template>
            <div class="col-lg-12 col-12">
              <div class="form-group required" style="margin-bottom: 20px;">
                <label for="name"> Message</label>
                <textarea rows="4" cols="20" class="form-control" formControlName="admission_message" placeholder=""
                  name="admission_message" required></textarea>
                <span class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.admission_message.errors?.required">Admission Message
                  is
                  required</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group" style="margin-bottom: 20px;">
                <ngx-recaptcha2 #captchaElem [siteKey]="sitekey" [useGlobalDomain]="false" formControlName="recaptcha">
                </ngx-recaptcha2>
                <div class="help-block text-danger"
                  *ngIf="submitted && admissionForm.controls.recaptcha.errors?.required ">
                  Please verify reCAPTCHA</div>
              </div>
            </div>
            <div class="col-12">

              <p class="text-danger text-center " *ngIf="form_validation!='' ">{{form_validation}}</p>
              <p class="text-center " style="color:green " *ngIf="form_success!='' ">{{form_success}}</p>
              <button type="submit" class="btn btn-primary  btn-lg mt3">Submit</button>

            </div>

          </div>
        </div>
      </div>
    </form>


</div>
