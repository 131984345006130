<!--CHnage-->
<div class="owl-drager" *ngIf="page_slider.length>0 && page_type=='home'">
  <owl-carousel-o [options]="cs.imagesliderOptions">
    <ng-container *ngFor="let slide of page_slider; let i=index">
      <ng-template carouselSlide [id]="slide.id">
          <a href="{{cs.apiUrl+'media/'+slide.slide_banner_name}}" data-lightbox="image" data-alt="{{slide.slide_caption}}" data-caption="{{slide.slide_caption}}" data-title="{{slide.slide_caption}}">
            <img  [src]="cs.apiUrl+'media/'+slide.slide_image_name" (click)="opengallerybox(i)" title="{{slide.slide_caption}}" alt="{{slide.slide_caption}}" style="width:100%">
          </a>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  </div>
  <!--CHnage-->
  <div class="owl-drager" *ngIf="page_slider.length>0 && page_type=='accreditation'">
    <owl-carousel-o [options]="cs.accredsliderOptions">
      <ng-container *ngFor="let slide of page_slider; let i=index">
        <ng-template carouselSlide [id]="slide.id">
          <a [href]="slide.external_link" target="_blank">
            <img  [src]="cs.apiUrl+'media/'+slide.slide_image_name"  title="{{slide.slide_caption}}" alt="{{slide.slide_caption}}" >
          </a>

        </ng-template>
      </ng-container>
    </owl-carousel-o>
    </div>

<div class="owl-drager" *ngIf="page_slider.length>0 && page_type!='home' && page_type!='accreditation'">
  <owl-carousel-o [options]="cs.imagesliderOptions">
    <ng-container *ngFor="let slide of page_slider; let i=index">
      <ng-template carouselSlide [id]="slide.id">
            <img  [src]="cs.apiUrl+'media/'+slide.slide_image_name" (click)="opengallerybox(i)" title="{{slide.slide_caption}}" alt="{{slide.slide_caption}}" style="width:100%">
      </ng-template>
    </ng-container>
  </owl-carousel-o>
  </div>

