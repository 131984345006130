import { Component, OnInit, Input,EventEmitter,Output} from '@angular/core';
import {CommonService} from './../../../utils/common.service';
@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css']
})
export class NavmenuComponent implements OnInit {
  @Input()  menuList:any = [];
  constructor(public cs:CommonService) { }

  ngOnInit(): void {

  }

}
