<ul class="mainlist">
  <ng-template ngFor let-menu [ngForOf]="cs.nav_menu" let-i="index">

    <li appExpandmenu  *ngIf="menu.children && menu.children.length>0">
      <a *ngIf="menu.link_id !=0" (click)="cs.openmenu(menu.slug)" >{{menu.link_name}} <i
          *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
      <a *ngIf="menu.link_id==0 && menu.custom_link !='#'" [href]="menu.custom_link"
        target="_blank">{{menu.link_name}} <i
          *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
      <a *ngIf="menu.link_id==0 && menu.custom_link =='#'">{{menu.link_name}} <i
          *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
    </li>

  <li  *ngIf="(menu.children && menu.children.length==0) || !menu.children">
    <a *ngIf="menu.link_id !=0" (click)="cs.openmenu(menu.slug)" >{{menu.link_name}} <i
        *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
    <a *ngIf="menu.link_id==0 && menu.custom_link !='#'" [href]="menu.custom_link"
      target="_blank">{{menu.link_name}} <i
        *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
    <a *ngIf="menu.link_id==0 && menu.custom_link =='#'">{{menu.link_name}} <i
        *ngIf="menu.children && menu.children.length>0" aria-hidden="true"></i></a>
  </li>
    <li *ngIf="menu.children && menu.children.length>0">
      <app-navmenu class="navsub" [menuList]="menu.children" ></app-navmenu>
    </li>
  </ng-template>
</ul>
