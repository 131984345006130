import { Component, OnInit } from '@angular/core';
import {CommonService} from './../../../utils/common.service';
@Component({
  selector: 'app-navlinks',
  templateUrl: './navlinks.component.html',
  styleUrls: ['./navlinks.component.css']
})
export class NavlinksComponent implements OnInit {

  constructor(public cs:CommonService) {

  }

  ngOnInit(): void {
  }

}
