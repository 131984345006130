
import {CommonService} from './../../utils/common.service';
import { Component, OnInit, ViewChild, ElementRef,TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {ReCaptcha2Component} from 'ngx-captcha';
import { PixelService } from 'ngx-pixel';
//import {GoogleAnalyticsService} from './../../utils/google-analytics.service';
declare var require: any;
// const FileSaver = require('file-saver');
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public form_validation="";
  upatern=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/;
  modalRef: BsModalRef;
  public form_success="";
  public submitted=false;
  public sitekey=environment.ggglcy;
  @ViewChild('footerschooltour', { static: true }) footerschooltour: ElementRef;
  @ViewChild('footerabout', { static: true }) footerabout: ElementRef;
  @ViewChild('footertimmings', { static: true }) footertimmings: ElementRef;
  @ViewChild('captchaElem1', { static: false }) captchaElem1: ReCaptcha2Component;
  constructor(private pixel: PixelService,public cs:CommonService,private modalService: BsModalService,
  //  public googleAnalyticsService:GoogleAnalyticsService
    ) { };
  openEnquiry(template: TemplateRef<any>) {
    this.form_success ="";
    this.form_validation="";
    this.modalRef = this.modalService.show(template);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  openschooltour(template: TemplateRef<any>){
    this.form_success ="";
    this.form_validation="";
    this.modalRef = this.modalService.show(template );

  }
  schoolForm = new FormGroup({
    user_name: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    user_email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.upatern),
    ]),
    contact_no: new FormControl('', Validators.required),
    preferred_tour_type: new FormControl('', Validators.required),
    additonal_notes: new FormControl(''),
    recaptcha: new FormControl('', Validators.required)
  });
   downloadbroucher(){
  //this.cs.apiUrl+'media/page_banner_1.webpp'
 // var blob = new Blob(['http://africau.edu/images/default/sample.pdf'], { type: "application/pdf"});
  //change download.pdf to the name of whatever you want your file to be
  //  FileSaver.saveAs(blob , 'Diyafah Internationl School Prospects');
  }
  numberOnly(evt):boolean
{
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}
  ngOnInit(): void {
    this.cs
      .postData({ sourceid: 'cms/site-data', info: { company_id: 1 } })
      .subscribe((data) => {
        if (data['status'] == 1) {
          this.cs.common_page_blocks=data.page_blocks;
          for (var i in this.cs.common_page_blocks) {
            try {
              this[
                this.cs.common_page_blocks[i]['block_id']
              ].nativeElement.innerHTML =  this.cs.common_page_blocks[i][
                'block_content'
              ];
            } catch (error) {}
          }
        }
      });
  }
  resetvalues() {
    this.schoolForm.patchValue({
      user_name: '',
      user_email: '',
      contact_no: '',
      preferred_tour_type: '',
      additonal_notes: '',
      recaptcha: '',
    });
    this.submitted = false;
    try {
      this.captchaElem1.resetCaptcha();
   } catch (error) {

    }

  }
  public onsubmit(form) {
    this.submitted = true;
    if (form.valid) {
      this.form_validation="";
      this.cs
        .postData({ sourceid: 'cms/contactus', info: this.schoolForm.value })
        .subscribe((data: any) => {
          //   console.log(JSON.stringify(data));
          if (data.status == 1) {
          this.pixel.track('Lead', { content_category: 'enquiry',content_name:"Book A School Tour"  });
           this.cs.trigger_googleevent("enquiry", "Lead", "submit","Book A School Tour", 1);
            this.resetvalues();
            this.form_success =
              'Thank you for contacting us. We will get back to you shortly.';
          } else {
            this.form_validation = 'Some Error Occured';
          }
        });
    } else {
     this.form_validation = 'Fill out Mandatory Fields';
    }
  }

}
