
<app-preloader></app-preloader>

<app-header *ngIf=" location !='' &&  !(location.toString().includes('error/maintenance')) "></app-header>
<div [ngClass]="{'main-block': !(location.toString().includes('error/maintenance')) }">
  <router-outlet ></router-outlet>
</div>
<app-footer  *ngIf=" location !='' && !(location.toString().includes('error/maintenance')) && !(location.toString().includes('error/404'))"></app-footer>

<!-- <div class="lds-ring"><div></div><div></div><div></div><div></div></div> -->

