import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmissionenquiryComponent } from './admissionenquiry.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule, Routes } from '@angular/router';
import {SeomoduleModule} from '../../common/seomodule/seomodule.module';
import {OwlimagecaursolModule} from '../../common/owlimagecaursol/owlimagecaursol.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
const routes: Routes = [
  {path: '',  component:AdmissionenquiryComponent}

 ];

@NgModule({
  declarations: [
    AdmissionenquiryComponent,

  ],
  imports: [
    OwlimagecaursolModule, SeomoduleModule,
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgxCaptchaModule
    ,RouterModule.forChild(routes)
  ],
  exports:[
    AdmissionenquiryComponent
  ]
})
export class AdmissionenquiryModule {

 }
