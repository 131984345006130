import {  Component,  Inject,  OnInit,  ApplicationRef,  PLATFORM_ID,} from '@angular/core';
import { NavigationStart, NavigationCancel, Router,ActivatedRoute } from '@angular/router';
declare let $: any, window: any;
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from './components/utils/common.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import {  ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from 'ngx-pixel';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  location: any="";
  public is_admin = 0;
  public data_loaded = 0;
  public menu_type = 1;
   routerSubscription: any;
  constructor(
    public cs: CommonService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public appRef: ApplicationRef,
    domSanitizer: DomSanitizer,
    public activeRoute:ActivatedRoute ,
     private gtmService: GoogleTagManagerService,
     private pixel: PixelService
  ) {
    this.cs
      .postData({ sourceid: 'cms/site-data', info: { company_id: 1 } })
      .subscribe((data) => {
        if (data['status'] == 1) {
          this.data_loaded = 1;
          this.cs.site_info = data.siteinfo;
          if(this.cs.site_info['site_under_maintenance']=='1'){
            this.router.navigate(['/error/maintenance']);
          }
          try {
            this.cs.site_info['copy_right'] = this.cs.site_info[
              'copy_right'
            ].replace('{year}', new Date().getFullYear());
            this.cs.site_info['social_links'] = JSON.parse(
              this.cs.site_info['social_links']
            );
            this.cs.common_page_blocks=data.page_blocks;

          } catch (error) {}
          this.cs.nav_menu = data.navmenu;
          // this.cs.services_menu = data.servicesmenu;
          this.cs.footer_menu = data.footer_menu;
        } else {
        }
      });
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
         // console.log("================in iffff router event");
            const gtmTag = {
                event: 'page',
                pageName: item.url
            };
           // console.log("Router event Gtag=",gtmTag);
            this.gtmService.pushTag(gtmTag);
            this.pixel.track('PageView');

        }
    });
  }
  title = 'diyafah';
  ngOnInit() {
    this.recallJsFuntions();
  }
  ngAfterViewInit(){

  }
  recallJsFuntions() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {

       if (isPlatformBrowser(this.platformId)) {
        $('.preloader').show();


       }
      }
    });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {

        if (isPlatformBrowser(this.platformId)) {

          $.getScript('../assets/js/custom.js');
          if(this.cs.show_loader_show==1){
            setTimeout(() => {$('.preloader').fadeOut('slow');}, 2000)
          }else{
            this.cs.show_loader_show=1;
            setTimeout(() => { $('.preloader').fadeOut('slow');},2000)
          }


          this.location = this.router.url;
          if (!(event instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0);
        }
      });
  }
}
