import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule, Routes } from '@angular/router';
import {AdmissionenquiryModule} from '../../pages/admissionenquiry/admissionenquiry.module';

const routes: Routes = [
  {path: '',  component:FooterComponent}

 ];

@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [

    CommonModule,
    FormsModule,
    AdmissionenquiryModule,
    ReactiveFormsModule,
    NgxCaptchaModule,RouterModule.forChild(routes)
  ],
  exports:[
    FooterComponent
  ]

})
export class FooterModule { }
