<ng-template [ngIf]="content.published=='2'">

  <div>
    <h2>Work in Progress...</h2>
  </div>

</ng-template>
<ng-template [ngIf]="content.published=='1'">

  <p><span class="svgicon calender lightdark" *ngIf="(content.page_category=='3' || content.page_category=='2') && content.page_type=='1' && content.parent_id != '0'">{{content.event_date | date:mediumDate}}</span></p>
  <ng-template [ngIf]="content.page_description !=''">
    <div appRouterDirective [innerHTML]="content['page_description']">
    </div>
  </ng-template>
  <section class="inner-content" *ngIf="faqs.length>0 && disablefaqs =='0'">
    <div class="container ">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-12">
          <div class="faq-accordion">
            <ul class="accordion">
              <li class="accordion-item" *ngFor="let faq of faqs">
                <a class="accordion-title" href="javascript:void(0)">
                  <i></i> {{faq.question}}
                </a>
                <div class="accordion-content">
                  <p>{{faq.answer}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</section>
</ng-template>
