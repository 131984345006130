import { Injectable, Inject, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var MathJax: any;
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment'
import { Location } from '@angular/common';
import { Router} from '@angular/router';
import { pluck } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + environment.PHASEPRASE
  })
};
@Injectable()
export class CommonService {
  public CKEditorConfig={
    allowedContent:true
  };
  public search_term  = '';
   public site_info = [];
  public nav_menu = [];
  public footer_menu=[];
  public media_setup:any={};
  public show_loader_show=0;
  public canonicalLink=environment.domain;
  @ViewChild("myckeditor", /* TODO: add static flag */ { static: false }) mckeditor: any;
  public menu_types=['Nav Bar','Footer Menu 1','Footer Menu 2','Footer Menu 3'];
 public page_cats={2:'blog',3:'school-events',4:'diyafah-in-the-news'}
 public parent_pages=[74,76];
 public page_catids=[2,3,4]
  public featured =[''];
  public store_type =[''];
  public app_settings:any = { theme: 'smart-blue-light', sidenavIsOpened: '1', sidenavIsPinned: true, '1': 'vertical', fixedHeader: true };
  public user_session:any = {  };
  public uridata:any={id:'',type:''};
  public live_url=environment.sevurl;
  public apiUrl = environment.host;  // URL to web api
  public color = 'primary';
  public mode = 'indeterminate';
  public value = 50;
  public common_page_blocks=[];
  public displayProgressSpinner = false;
  public spinnerWithoutBackdrop = false;
  public show_loader=1;

  // Display progress spinner for 3 secs on click of button
  public showProgressSpinner = () => {
    this.displayProgressSpinner = true;
    setTimeout(() => {
      this.displayProgressSpinner = false;
    }, 3000);
  };
  public showSpinnerWithoutBackdrop = () => {
    this.spinnerWithoutBackdrop = true;
    setTimeout(() => {
      this.spinnerWithoutBackdrop = false;
    }, 3000);
  };
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  openlink(link,type,action,linktype){
    this.trigger_googleevent("enquiry", "Lead",action,type,  1);
    if(linktype=='e'){
      window.open(link, '_blank');
    }else{
      window.open(link);
    }
  }
  public openmenu(data: any,type) {
    //aconsole.log(data['parent_id']);
   //console.log(this.parent_pages.indexOf(data['parent_id'])+">>>>>>>>>>>"+JSON.stringify(data))
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("mySidenav").classList.remove('side-open');
      document.getElementsByTagName("body")[0].classList.remove('side-open');

    if(data.slug && data.slug !=''){

      if(data['page_cat_id']){
        var index=this.page_catids.indexOf(data['page_cat_id']);
        if(index>-1){
          if(index==0){
            this.router.navigate(['/blog',data.slug]);

          }else{
            this.router.navigate(['/',this.page_cats[data['page_cat_id']]]);
          }

        }else if(this.parent_pages.indexOf(data['parent_id']) >-1){
          this.router.navigate(['/',data['parent_slug']]);
        }else{
          this.router.navigate(['/',data.slug]);
        }
      }else{
        this.router.navigate(['/',data.slug]);
      }

    }else{
      this.router.navigate(['/',data]);
    }

  }
   numberDotOnly(evt:any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 46 || charCode > 57)) {
        return false;
    }
    return true;
}
  constructor( private gtmService: GoogleTagManagerService,public _sanitizer:DomSanitizer,public router:Router,public _location: Location, private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {

   // localStorage.getItem('smarte2e_bbcards').thn;
  };
    postData(data:any) {

    return this.http.post(this.apiUrl +data.sourceid, JSON.stringify(data.info), httpOptions)
      .pipe(
        tap((res: any) => {
          return res;
        }, err => {
         console.log(err);
        })
      );
  }
  public openpdf(br:any){
    window.open(br, '_blank');

  }
  public santiseurl(url:any){
    var sanatisedurl= this._sanitizer.bypassSecurityTrustResourceUrl(url);
   return sanatisedurl;
  }


  public trigger_googleevent( eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null){
      this.gtmService.pushTag({
        event: eventName,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
        eventValue: eventValue
      })
  }
  public history_back() {
    this._location.back();
  }

  imageToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }
  public  isBase64(str:any)
{
    return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,3}$/.test(str);
}


  /*public postData<T>(data: object): Observable<T> {
    //console.log(data['sourceid']);
    // console.log(JSON.stringify(data['info']));
    return this.http.post<T>(data['sourceid'], data['info']);
  }*/
  public uploadfiles(params: Array<string>, files: Array<File>) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Basic ' + 'ZW5hMjBwamgxNToyNmJhZjBjZjQ5Y2Q4ZmM4NGE5NGYxMmVhNGJiM2NmZA==');
    return this.http.post(params[0], files)
  }

  public arrayOne(n: number): any[] {
    return Array(n);
  }
  public arrayThree(n: number, startFrom: number): number[] {
    return [Array(n).keys()].map(i => Number(i) + Number(startFrom));
  }

  public setusersession(data:any) {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.setItem('smarte2e_bbcards', data);
    this.user_session = data;
    }
    // data=data==""? {email_id:"",display_name:'',profile_pic:'',id:''}: data;
  }
  makeFileRequest(url: string, params: {}, files: Array<File>) {
    return new Promise((resolve, reject) => {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      formData.append("data", JSON.stringify(params));
      // console.log(formData);
      for (var i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i].name);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", environment.host + url, true);
      xhr.send(formData);
    });
  }
  private handleError(error: any) {
    //  console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

 public  bannerliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoHeight : true,
    autoplay:true,
    navText: [''],

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
  public imagesliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    center: true,
    dots: false,
    margin: 0,
    autoplay:true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: [
      '<a class="svgicon left-arrow-slider white "></a>',
      '<a class="svgicon right-arrow-slider white"></a>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: true,
  };
public accredsliderOptions: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  center: true,
  dots: false,
  margin: 0,
  autoplay:true,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  navText: [
    '<a class="svgicon left-arrow-slider dark"></a>',
    '<a class="svgicon right-arrow-slider dark"></a>',
  ],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 3,
    },
    740: {
      items: 4,
    },
    940: {
      items: 5,
    },
  },
  nav: true,
};
}
@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json').set('Authorization', 'Basic ' + 'ZW5hMjBwamgxNToyNmJhZjBjZjQ5Y2Q4ZmM4NGE5NGYxMmVhNGJiM2NmZA==') });
    }
    // req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    //  console.log(JSON.stringify(req.headers));
    return next.handle(req);
  }
}

