<section class="school-tour">
  <div class="container-fluid">
    <div class="row  d-flex justify-content-center align-items-center text-center">
      <div class="col-lg-6 col-md-12 col-12">
        <div class="row  imgbg" [ngStyle]="{'background-image': 'url('+cs.apiUrl+'media/book_now.webp)'}">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-12 tour-action">
        <div class="row d-flex justify-content-center align-items-center">
          <h2 class="text-center text-white">Book a School Tour</h2>
          <h5 class="text-center w-100 text-white">Avail a personalised or virtual school tour</h5>
          <p class="text-center"><button class="btn btn-primary white" id="bookschooltour" (click)="openschooltour(schooltourform)">BOOK
              NOW</button></p>
        </div>
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12" #footerabout>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <h5>Quick Links</h5>
        <ul class="footer-list " *ngIf="cs.footer_menu.length>0">
          <ng-template ngFor let-fmenu [ngForOf]="cs.footer_menu">
            <li class="d-flex justify-content-start align-items-center">
              <a *ngIf="fmenu.link_id==0 " href="{{fmenu.custom_link}}" target="_blank">{{ fmenu.link_name }}</a>
              <a *ngIf="fmenu.link_id !=0 " [routerLink]="fmenu.slug ">{{ fmenu.link_name }}</a>
            </li>
          </ng-template>
        </ul>
      </div>
      <!-- <div class="col-lg-3 col-md-6 col-12" #footertimmings>

      </div> -->
      <div class="col-lg-4 col-md-4 col-12">
        <h5>Get in Touch</h5>
        <p><span class="svgicon location white">{{cs.site_info['headoffice_address1']}}
            <ng-template [ngIf]="cs.site_info['headoffice_address2']!=''"> {{cs.site_info['headoffice_address2']}},
            </ng-template>
            <ng-template [ngIf]="cs.site_info['headoffice_state']!=''"> {{cs.site_info['headoffice_state']}},
            </ng-template>
            <ng-template [ngIf]="cs.site_info['headoffice_country']!=''">{{cs.site_info['headoffice_country']}},
            </ng-template> {{cs.site_info['headoffice_zipcocde']}}
          </span></p>
        <p>
          <ng-template [ngIf]="cs.site_info['headoffice_email_id'] !=''"><a class="svgicon email white"
              href="mailto:{{cs.site_info['headoffice_email_id']}}"> {{cs.site_info['headoffice_email_id']}}</a>
          </ng-template>
        </p>
        <p>
          <ng-template [ngIf]="cs.site_info['headoffice_contact_no1'] !=''"><a class="svgicon call white"
              href="tel:{{cs.site_info['headoffice_contact_no1']}}"> {{cs.site_info['headoffice_contact_no1']}}</a>
          </ng-template>
        </p>
        <p *ngIf="cs.site_info['headoffice_faxno'] !=''"><a class="svgicon location white">Makani No:
            {{cs.site_info['headoffice_faxno']}}</a>
        </p>

        <section id="lab_social_icon_footer">
          <!-- Include Font Awesome Stylesheet in Header -->

          <ng-template ngFor let-social [ngForOf]="cs.site_info['social_links'] ">

            <a *ngIf="social.url !=''" href="{{social.url}}" target="_blank"><i class="{{social.icon}}"></i></a>
          </ng-template>

          <!-- <a href="https://twitter.com/bootsnipp"><i id="social-tw"
                    class="fa fa-twitter-square fa-3x social"></i></a>
                <a href="https://plus.google.com/+Bootsnipp-page"><i id="social-gp"
                    class="fa fa-google-plus-square fa-3x social"></i></a>
                <a href="mailto:#"><i id="social-em" class="fa fa-envelope-square fa-3x social"></i></a>-->

        </section>
      </div>
    </div>
  </div>
</footer>
<div class="copyright">
  <div class="col-12">
    <div class="row  d-flex justify-content-center align-items-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12" [innerHTML]="cs.site_info['copy_right']"></div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center"
        [innerHTML]="cs.site_info['powered_by']"></div>
    </div>
  </div>

</div>

<!--<div class="sticky-actions">
  <ul>
    <li (click)="openEnquiry(admission)">
      <a class="svgicon chat-withus white"></a>
    </li>
    <li (click)="openModal(template)">
      <a class="svgicon download white"></a>
    </li>
  </ul>
</div>-->
<div class="sticky-container d-flex d-lg-none">
  <ul class="stickyblock">
    <li (click)="openEnquiry(admission)" class="d-flex justify-content-start align-items-center" id="enquireid">
      <a class="svgicon chat-withus white"></a>
      <span>Enquire Now</span>
    </li>
    <li class="d-flex justify-content-start align-items-center">
      <a target="_blank" href="https://diyafah.online:8887/alpha/f?p=285:1:2267683552276:::::"
        class="svgicon write white">
        <span>Registration Form</span></a>
    </li>

    <!-- <li (click)="openModal(template)"  class="d-flex justify-content-start align-items-center">
      <a class="svgicon download white"></a>
      <span>Download Prospectus</span>
    </li>-->
  </ul>
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Download Prospectus</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to download?</p>
    <br>
    <p>
      <button style="margin:10px" type="button" class="btn btn-primary"
        (click)="modalRef.hide();downloadbroucher()">Confirm</button>
      <button style="margin:10px;color: #212529;" type="button" class="btn btn-light"
        (click)="modalRef.hide()">Close</button>
    </p>
  </div>
</ng-template>
<ng-template #admission>
  <app-admissionenquiry (closeModal)="modalRef.hide()"></app-admissionenquiry>
</ng-template>
<ng-template #admission>
  <app-admissionenquiry (closeModal)="modalRef.hide()"></app-admissionenquiry>
</ng-template>
<ng-template #schooltourform>

  <div class="modal-body" style="padding:0 !important">
    <div class="col-lg-12 col-sm-6 col-12">

      <form [formGroup]="schoolForm" (ngSubmit)="onsubmit(schoolForm)" #mschoolForm="ngForm">
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12" style="padding:10px 15px !important">
            <div class="row">
              <div class="col-12">
                <h2 class="modal-title pull-left enquiry-header">Book A School Tour</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr class="w-100">
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group required">
                  <label for="name"> Name</label>
                  <input type="text" class="form-control" formControlName="user_name" placeholder="" name="user_name"
                    required maxlength="50">
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.user_name.errors?.required">Your Name is required</span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group required">
                  <label for="name"> Email ID</label>
                  <input type="text" class="form-control" formControlName="user_email" placeholder="" name="user_email"
                    required maxlength="100">
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.user_email.errors?.required">Your Email ID is
                    required</span>
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.user_email.errors?.pattern">Please enter a valid Email
                    Id</span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group required">
                  <label for="name">Phone Number</label>
                  <input type="text" class="form-control" (keypress)="numberOnly($event)" formControlName="contact_no"
                    placeholder="" name="contact_no" required maxlength="17">
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.contact_no.errors?.required">Your Phone Number is
                    required</span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="name">Preferred Type of Tour</label>
                  <select class="form-control" name="prefredtourtype" title="Click to Select"
                    formControlName="preferred_tour_type">
                    <option value="1">Virtual</option>
                    <option value="2">Physical</option>
                  </select>
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.preferred_tour_type.errors?.required">Please select
                    Preferred Type of Tour</span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="name">Additional Requests Pertinent to the Tour (Optional)</label>
                  <textarea rows="4" cols="20" maxlength="500" class="form-control" formControlName="additonal_notes"
                    placeholder="" name="additonal_notes"></textarea>

                </div>
              </div>
              <!-- <div class="form-group">
                  <label for="name">Preferred Date of Visit</label>
                  <input type="text" class="form-control"  formControlName="preferred_date"
                    placeholder="" name="preferred_date" bsDatepicker required maxlength="15">
                  <span class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.preferred_date.errors?.required">Date Is Required</span>
                </div> -->
              <div class="col-12">
                <div class="form-group">
                  <ngx-recaptcha2 #captchaElem1 [siteKey]="sitekey" [useGlobalDomain]="false"
                    formControlName="recaptcha">
                  </ngx-recaptcha2>
                  <div class="help-block text-danger"
                    *ngIf="submitted && schoolForm.controls.recaptcha.errors?.required ">Please verify reCAPTCHA</div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <p class="text-danger text-center " class="w-100" *ngIf="form_validation!='' ">{{form_validation}}
                    </p>
                    <p class="text-center " class="w-100" style="color:green " *ngIf="form_success!='' ">
                      {{form_success}}</p>
                    <button type="submit" class="btn btn-primary btn-lg mt3">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
