

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './components/common/header/header.module';
import { FooterModule } from "./components/common/footer/footer.module";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";

import { CommonService } from './components/utils/common.service';
import { HttpClientModule,HTTP_INTERCEPTORS} from "@angular/common/http";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {RouterDirective} from './components/utils/router-directive.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {GoogleAnalyticsService} from './components/utils/google-analytics.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { PixelModule } from 'ngx-pixel';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    RouterDirective
  ],
  imports: [

    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    HeaderModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FooterModule,
    PixelModule.forRoot({ enabled: true, pixelId: '401138733817132' }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-KJXHH5B',
      // gtm_auth: YOUR_GTM_AUTH,
      // gtm_preview: YOUR_GTM_ENV
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
     // registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [CommonService,GoogleAnalyticsService, { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
