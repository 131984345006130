import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlimagecaursolComponent } from './owlimagecaursol/owlimagecaursol.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  declarations: [
    OwlimagecaursolComponent
  ],
  exports:[
    OwlimagecaursolComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
  //  LightboxModule
  ]
})
export class OwlimagecaursolModule { }
