
import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { StartedPipe } from './started.pipe';
import { InArrayPipe}        from './inarray.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { SafeurlPipe } from './safeurl.pipe';
import {SlugifyPipe} from './slugify.pipe';

@NgModule({
  imports: [

  ],
  declarations: [
    KeysPipe,
    StartedPipe,
    InArrayPipe,
    SafeHtmlPipe,
    SafeurlPipe,
    SlugifyPipe

  ],
  exports: [
    KeysPipe,
    StartedPipe,
    InArrayPipe,
    SafeHtmlPipe,
    SafeurlPipe,
    SlugifyPipe
  ], })
export class PipeModule {
  static forRoot()
  { return {
    ngModule: PipeModule,
    providers: [],
  };
  }
}
