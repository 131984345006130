<header>
  <div class="row justify-content-center justify-content-lg-between align-items-center top-header">
    <div class="col-12 col-lg-auto">
      <ul class="nav top-navicons left  d-flex justify-content-center  justify-content-lg-between  align-items-center ">
        <li class="nav-item my-2 px-sm-2 btn-group" data-toggle="dropdown">
          <a class="svgicon portallogin dark" title="Portal Login"></a>
          <div class="dropdown-menu dropdown-menu-right" #mportal id="mportalmain">
          </div>
        </li>
        <li class="nav-item my-2 px-sm-2" title="WhatsApp">
          <a class="svgicon whatsapp dark" style="cursor:pointer"
            (click)="cs.openlink('https://api.whatsapp.com/send?phone='+cs.site_info['headoffice_contact_no2']+'&text=Hi','Phone Call Initiated','call','e')"
            title="WhatsApp"></a>
        </li>
        <li class="nav-item my-2 px-sm-2">
          <a class="svgicon callring dark"
            (click)="cs.openlink('tel:'+cs.site_info['headoffice_contact_no1'],'Whatsapp Chat Initiated','chat','n')"
            style="cursor:pointer" title="Call"><p class="d-flex d-md-none mb-0">{{cs.site_info['headoffice_contact_no1']}}</p></a>
        </li>
        <li class="nav-item my-2  d-none d-md-flex">
          <a class="svgicon chat-withus dark"
          (click)="triggerenquiry()"
            style="cursor:pointer" title="Enquire Now">Enquire Now</a>
        </li>
        <li class="nav-item my-2 vertical-line  d-none d-md-flex">

        </li>
        <li class="nav-item my-2  d-none d-md-flex">
          <a class="svgicon write dark"
          (click)="cs.openlink('https://diyafah.online:8887/alpha/f?p=313:1:4852427518216:::::','Registration Form Initiated','registration','e')"
            style="cursor:pointer" title="Registration Form">Registration Form</a>
        </li>
        <!-- <li class=" nav-item my-2 ">
              <a class="svgicon email dark" href="mailto:{{cs.site_info['headoffice_email_id']}}" title="Email Us"></a>
            </li> -->
      </ul>
    </div>
    <div class="col-auto d-none d-lg-flex">

      <ul class="nav top-navicons right w-100">
        <li class="nav-item my-2 d-none d-xl-flex" title="DISMUN" (click)="cs.openlink('http://diyafah.com/dismun/','Registration Form Initiated','registration','e')">
          <div class="dismun" >
            <p>DISMUN</p>
          </div>
        </li>
        <li class="nav-item my-2" title="Register Now" (click)="cs.openlink('https://diyafah.online:8887/alpha/f?p=313:1:4852427518216:::::','Registration Form Initiated','registration','e')">
          <div class="admission-open" >
            <p>Admissions Open (2025-26)</p>
          </div>
        </li>

      </ul>
    </div>
  </div>
  <nav class="navbar navbar-expand-md navbar-light ">
    <div id="navbarNav" style="width:100%">
      <div class="row justify-content-center align-items-center">
        <!-- <img src="{{cs.apiUrl}}media/piink-ribbon-breast-cancer.webp" class="d-none d-xl-flex" style="width:130px"> -->
        <img style="margin:0 15px;" src="{{cs.apiUrl}}media/{{cs.site_info['logo_file_name']}}" class="logo rlink" alt="Diyafah international school logo"  [routerLink]="['/']">
        <img src="{{cs.apiUrl}}media/Transparent_DIS_10 Years Logo.png"  class="badge10 d-none d-md-flex" alt="DIS 10 years logo">
        <ul class="nav navicons justify-content-end align-items-center d-none d-xl-flex" style="max-width:200px">
          <!-- <li class=" nav-item my-2 ">
            <a class="svgicon callring dark"
              (click)="cs.openlink('tel:'+cs.site_info['headoffice_contact_no1'],'Whatsapp Chat Initiated','chat','n')"
              style="cursor:pointer" title="Call"></a>
          </li>

          <li class="nav-item my-2 " title="WhatsApp">
            <a class="svgicon whatsapp dark" style="cursor:pointer"
              (click)="cs.openlink('https://api.whatsapp.com/send?phone='+cs.site_info['headoffice_contact_no2']+'&text=','Phone Call Initiated','call','e')"
              title="WhatsApp"></a>
          </li>
          <li class="nav-item my-2 btn-group" data-toggle="dropdown">
            <a class="svgicon portallogin dark" title="Portal Login"></a>
            <div class="dropdown-menu dropdown-menu-right" #mportal id="mportalmain">
            </div>
          </li> -->
          <li class="nav-item my-2 search-block">
            <div class="search">
              <ng-select #select [multiple]="false" (change)="navigatefromsearch()" class="auto-grow"
                placeholder="Search the Website" typeToSearchText="Type to search" [items]="web_search_results"
                bindValue="slug" notFoundText="No Results found" [formControl]="search_term" [typeahead]="typeahead"
                [minTermLength]="3">
                <ng-template ng-option-tmp let-item="item">
                  {{item.page_name}} <br />
                  <small>{{item.category_name}}</small>
                </ng-template>
              </ng-select>
            </div>
          </li>
        </ul>
        <div class='menubtn row d-flex justify-content-center align-items-center' onclick="openNav()">
          <span class="svgicon menu-icon dark" style="margin-right:10px">
          </span> <span class="d-none d-sm-block">MENU</span>
        </div>
      </div>
    </div>
  </nav>
  <section id="main" class="marque-slider" *ngIf="marque_text !=''">
    <div class="col-12 d-flex flex-row bd-highlight mb-3" >
  <marquee direction="left"  #notification ><div  [innerHTML]="marque_text"></div></marquee>
  </div>
  </section>
</header>
<div id="mySidenav" class="sidenav">
  <div class="container-fluid">
    <div class='primeheader d-flex w-100 justify-content-start align-items-center'>
      <div class='primemenubtn row d-inline-flex justify-content-center align-items-center' onclick="closeNav()">
        <span class="dark" style="margin-right: 10px;
        font-weight: 500;
        font-size: 30px;">&times;
        </span> <span>CLOSE</span>
      </div>
    </div>
    <div class="row d-none d-xl-flex primesection" *ngIf="cs.nav_menu.length>0">
      <!--d-none d-sm-block-->
      <div class="col-lg-4 col-md-4 col-12">
        <ul class="mainlist">
          <li *ngFor="let menu of cs.nav_menu;let i=index" (mouseenter)="loadsubmenu(i)">
            <a *ngIf="menu.link_id !=0" (click)="cs.openmenu(menu.slug)">{{menu.link_name}}</a>
            <a *ngIf="menu.link_id==0 && menu.custom_link !='#'" [href]="menu.custom_link"
              target="_blank">{{menu.link_name}}</a>
            <a *ngIf="menu.link_id==0 && menu.custom_link =='#'">{{menu.link_name}}</a>
          </li>
        </ul>
        <!--<a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                <a href="#">About</a>
                <a href="#">Services</a>
                <a href="#">Clients</a>
                <a href="#">Contact</a>-->
      </div>
      <div class="col-xl-8 col-md-8 col-sm-8 col-12 submenu" *ngIf="submenu.length>0">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <ul class="sublist">
              <li *ngFor="let menu of submenu;let j=index" (mouseenter)="loadsubsubmenu(j)">
                <a *ngIf="menu.link_id !=0" (click)="cs.openmenu(menu.slug)">{{menu.link_name}}</a>
                <a *ngIf="menu.link_id==0 && menu.custom_link !='#'" [href]="menu.custom_link"
                  target="_blank">{{menu.link_name}}</a>
                <a *ngIf="menu.link_id==0 && menu.custom_link =='#'">{{menu.link_name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid d-xl-none mobilemenu">
      <app-navlinks></app-navlinks>
      <ul class="nav navicons d-flex align-items-center justify-content-center">
        <li class=" nav-item my-2 ">
          <a class="svgicon callring dark" href="tel:{{cs.site_info['headoffice_contact_no1']}}" title="Call"></a>
        </li>
        <!-- <li class=" nav-item my-2 ">
          <a class="svgicon email dark" href="mailto:{{cs.site_info['headoffice_email_id']}}" title="Email Us"></a>
        </li> -->
        <li class="nav-item my-2 " title="WhatsApp">
          <a class="svgicon whatsapp dark" target="_blank"
            href="https://api.whatsapp.com/send?phone={{cs.site_info['headoffice_contact_no2']}}&text=Hi"
            title="WhatsApp"></a>
        </li>
        <li class="nav-item my-2 btn-group" data-toggle="dropdown">
          <a class=" svgicon portallogin dark" title="Portal Login"></a>
          <div class="dropdown-menu dropdown-menu-right" #mportal1 id="msubportal">
          </div>
          <!-- <div class="btn-group">
            <button type="button" class=" dropdown-toggle" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <span class=" portallogin svgicon dark"></span>
            </button>

          </div> -->
        </li>
        <li class="nav-item my-2 search-block">
          <div class="search">

            <ng-select highlightColor="#9575cd" [multiple]="false" (change)="navigatefromsearch(1)"
              class="auto-grow focused" placeholder="Search the Website" typeToSearchText="Type to search"
              [items]="web_search_results" bindValue="slug" notFoundText="No Results found" [formControl]="search_term"
              [typeahead]="typeahead" [minTermLength]="3">
              <ng-template ng-option-tmp let-item="item">
                {{item.page_name}} <br />
                <small>{{item.category_name}}</small>
              </ng-template>
            </ng-select>
            <!-- <input (keyup)="onKeyUp('')" type="text" placeholder="Search the Website" formControlName="search_term"
              class="form-control" />
              <span class="search-button">
                <span class="search-icon"></span>
              </span> -->
          </div>
        </li>
      </ul>
      <ul class="nav navicons d-flex align-items-center justify-content-center">
        <li class="nav-item my-2" title="DISMUN" (click)="cs.openlink('http://diyafah.com/dismun/','Registration Form Initiated','registration','e')">
          <div class="dismun" >
            <p>DISMUN</p>
          </div>
        </li>
        <li class="nav-item my-2" title="Register Now" (click)="cs.openlink('https://diyafah.online:8887/alpha/f?p=313:1:4852427518216:::::','Registration Form Initiated','registration','e')">
          <div class="admission-open" >
            <p>Admissions Open (2025-26)</p>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>

