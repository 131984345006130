declare var lightbox:any;
import { Component, OnInit,Input, Inject, PLATFORM_ID } from '@angular/core';
 import { Lightbox } from 'ngx-lightbox';
import { CommonService } from '../../../utils/common.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-owlimagecaursol',
  templateUrl: './owlimagecaursol.component.html',
  styleUrls: ['./owlimagecaursol.component.css']
})
export class OwlimagecaursolComponent implements OnInit {
  @Input()  page_slider:any = [];
  @Input() page_type="";
  constructor(public cs:CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  // private _lightbox: Lightbox,
     ) { }

  ngOnInit(): void {
    if(this.page_type=='home'){
      this.cs.imagesliderOptions['navText']=[
        '<a class="svgicon left-arrow-slider white "></a>',
        '<a class="svgicon right-arrow-slider white"></a>',
      ];
    }else{
      this.cs.imagesliderOptions['navText']=[
        '<a class="svgicon left-arrow-slider dark"></a>',
        '<a class="svgicon right-arrow-slider dark"></a>',
      ];
    }
    for(var i in  this.page_slider){

      this.page_slider[i]['src']=this.cs.apiUrl+'media/'+ this.page_slider[i]['slide_banner_name'];
      this.page_slider[i]['caption']=this.page_slider[i]['slide_banner_caption'];
      console.log(this.page_slider[i]['src']);
    }
     this.triggerquery();
  }
  triggerquery() {
    if (isPlatformBrowser(this.platformId)) {
      lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true,
        showImageNumberLabel:false,
      })
    }
  }
  opengallerybox(index: number): void {
    // open lightbox
  //this._lightbox.open(this.page_slider, index);
  }

  closegallerybox(): void {
    // close lightbox programmatically
 //  this._lightbox.close();
  }
}
