import { NgControl } from '@angular/forms';
import {Input, Directive, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core'
import { CommonService } from './../common.service';
import { DOCUMENT } from '@angular/common';

import { Router } from '@angular/router';
@Directive({
  selector: '[appMoveToHead]'
})
export class MoveToHeadDirective implements OnDestroy, OnInit {
  constructor(private renderer: Renderer2,public cs:CommonService,
              private elRef: ElementRef,
              @Inject(DOCUMENT) private document: Document,public router:Router) {

  }

ngOnInit(): void {

    this.renderer.appendChild(this.document.head, this.elRef.nativeElement);
    this.renderer.removeAttribute(this.elRef.nativeElement, 'appmovetohead');
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.document.head, this.elRef.nativeElement);
  }
}
