import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { NavlinksComponent } from './navlinks/navlinks.component';
import { RouterModule, Routes } from '@angular/router';
import {ExpandmenuDirective} from '../../utils/expandmenu.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    ExpandmenuDirective,
    HeaderComponent,
    NavmenuComponent,
    NavlinksComponent
  ],
  exports:[
    HeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class HeaderModule { }
