import { Directive, HostBinding, HostListener } from "@angular/core";
declare let $: any, jQuery: any;
@Directive({
  selector: '[appExpandmenu]'
})
export class ExpandmenuDirective {
  constructor() { }
    @HostBinding('class.opensub') isOpen = false;
    @HostListener('click') toggleOpen($event){
      $( ".mainlist li" ).removeClass("opensub" );
    this.isOpen = !this.isOpen;
    }
}
