import { Injectable,Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class TagsService {
  public apiUrl = environment.host;
  public domainurl=environment.domain;
  constructor(@Inject(DOCUMENT) private dom,private titleService: Title, private metaTagService: Meta,public router:Router) {
  }
  public updatetags(data) {
   //alert(JSON.stringify(data));
    // if (data['imageurl'] == null) {
    //   data['imageurl'] = this.apiUrl + "media/sm-banner.webp";
    //   data['image_alt'] =  environment.imagealt;
    // }
    this.titleService.setTitle(data['title']);
    this.metaTagService.updateTag({ name: 'description', content: data['description']});
    this.metaTagService.updateTag({ name: 'twitter:title', content: data['title'] });
    this.metaTagService.updateTag({ name: 'twitter:description', content: data['description'] });
   // this.metaTagService.updateTag({ name: 'twitter:image', content: data['imageurl'] });
    this.metaTagService.updateTag({ name: 'twitter:url', content: this.domainurl.slice(0, -1)+""+this.router.url });
    this.metaTagService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaTagService.updateTag({property: 'og:title', content: data['title']  });
    this.metaTagService.updateTag({ property: 'og:url', content: this.domainurl.slice(0, -1)+""+this.router.url});
   // this.metaTagService.updateTag({property: 'og:image', content: data['imageurl'] });
   // this.metaTagService.updateTag({property: 'og:image:secure_url', content: data['imageurl'] });
    this.metaTagService.updateTag({ property: 'og:description', content: data['description'] });
   // this.metaTagService.updateTag({ property: 'og:image:alt', content: data['image_alt'] });
    this.metaTagService.updateTag({ property: 'og:site_name', content:'Diyafah International School LLC' });
    // if (data['videourl'] != '') {
    //   this.metaTagService.updateTag({  name:'video',property: 'og:video', content: data['videourl'] });
    // }
    this.metaTagService.updateTag({ property: 'og:type', content: data['page_type'] });
   this.createCanonicalURL();
    if (data['page_type'] == 'author'){
      this.metaTagService.updateTag({ name: "author", content: data['author_name'] });
      this.metaTagService.updateTag({ property: "article:author", content: data['author_name'] });
      this.metaTagService.updateTag({ property: "article:published_time", content: data['publish_date'] });
      this.metaTagService.updateTag({ property: "article:publisher", content: this.domainurl });
    }

  }
  createCanonicalURL() {

    // const canonical= document.querySelector('link[rel="canonical"]');
    //   if (canonical !== null) {
    //     canonical['href'] = this.domainurl.slice(0, -1)+""+this.router.url;
    //   }else{
    //     let link: HTMLLinkElement = this.dom.createElement('link');
    //     link.setAttribute('rel', 'canonical');
    //     this.dom.head.appendChild(link);
    //     link.setAttribute('href', this.domainurl.slice(0, -1)+""+this.router.url);
    //   }

 }
  public generate_article(data): Observable<any> {
    return of({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": this.domainurl+""+this.router.url
      },
      "headline": data['title'],
      "image": [
        data['imageurl']
      ],
      "datePublished": data['publish_date'] ,
      "dateModified": data['modified_date'],
      "author": {
        "@type": "Person",
        "name": data['author_name']
      },
      "publisher": {
        "@type": "Organization",
        "name": "Diyafah",
        "logo": {
          "@type": "ImageObject",
          "url": data['site_logo']
        }
      }
    });
  }
  public generate_faqs(data): Observable<any> {
    var faqslist = [];
    for (var i in data) {
      faqslist.push({
        "@type": "Question",
        "name": data[i]['question'],
        "acceptedAnswer": {
          "@type": "Answer",
          "text": data[i]['answer'],
        }
      });
      if (Number(i) == data.length - 1) {
        return of({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": faqslist
        });
      }

    }

  }
  public generate_course(data): Observable<any> {
    return of({
      "@context": "https://schema.org",
      "@type": "Course",
      "name": data['title'],
      "description": data['description'],
      "provider": {
        "@type": "Organization",
        "name": "Diyafah",
        "sameAs": this.domainurl
      }
    })

  }

  public generate_coursereview(data): Observable<any> {
    return of({
      "@context": "https://schema.org",
      "@type": "Review",
      "name": data['title'],
      "url": this.domainurl+""+this.router.url,
      "image": data['imageurl'],
      "author": data['author'],
      "reviewBody": data['review'],
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": data['rating']
      },
      "itemReviewed": {
        "@type": "LocalBusiness",
        "name": data['title'],
        "priceRange": data['currency_format'] + "" + data['price'],
        "image": data['imageurl'],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "#118, Level 2, Brundavanam Road No 3,Avenue 2 Cross, Kakatiya Hills, Kavuri Hills Phase 1",
          "addressLocality": ['Madhapur'],
          "addressRegion": ['Telangana'],
          "postalCode": ['500081'],
          "telephone": ["+91-7893656868"],
          "addressCountry": {
            "@type": "Country",
            "name": ['India']
          }
        }
      }
    });
  }
  public generate_courseevents(schedules, data): Observable<any> {
    var allschedules_schema = [];
    for (var i in schedules) {
      var location: any = { "@type": "VirtualLocation", "url": this.domainurl+""+this.router.url };
      var event_attendance = "https://schema.org/OnlineEventAttendanceMode";
      if (schedules[i]['training_type'] == 2) {
        location = {
          "@type": "Place",
          "name": "Diyafah",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": schedules[i]['address_line1'] + " " + schedules[i]['address_line1'],
            "addressLocality": schedules[i]['city'],
            "postalCode": schedules[i]['zip_code'],
            "addressRegion": schedules[i]['address_region_code'],
            "addressCountry": schedules[i]['address_country_code']
          }
        }
        event_attendance = "https://schema.org/OfflineEventAttendanceMode";
      }
      allschedules_schema.push({
        "@context": "https://schema.org",
        "@type": "EducationEvent",
        "name": data['title'],
        "description": data['description'],
        "performer": {
          "@type": "Person",
          "name": schedules[i]['faculty_name']
        },
        "image": data['imageurl'],
        "eventAttendanceMode": event_attendance,
        "eventStatus": "https://schema.org/EventScheduled",
        "location": location,
        "offers": {
          "@type": "AggregateOffer",
          "lowPrice": schedules[i]['course_offer_fee'],
          "url":this.domainurl+""+this.router.url,
          "availability": "https://schema.org/InStock",
          "validFrom": schedules[i]['course_end_date'],
          "price": schedules[i]['course_fee'],
          "priceCurrency": schedules[i]['currency_format']
        },
        "startDate": moment(schedules[i]['course_start_date']).format('YYYY-MM-DD'),
        "endDate": moment(schedules[i]['course_end_date']).format('YYYY-MM-DD'),
        "url": this.domainurl+""+this.router.url,
        "duration": schedules[i]['course_duration'],
        "organizer": {
          "@type": "Organization",
          "name": "Diyafah",
          "url":this.domainurl+""+this.domainurl
        }
      })
      if (Number(i) == schedules.length - 1) {
        return of(allschedules_schema);
      }
    }

  }
  public generate_event_schedules(schedules, data): Observable<any> {
    var allschedules_schema = [];
    for (var i in schedules) {
      var location: any = { "@type": "VirtualLocation", "url":this.domainurl+""+this.router.url };
      var event_attendance = "https://schema.org/OnlineEventAttendanceMode";
      if (schedules[i]['training_type'] == 2) {
        location = {
          "@type": "Place",
          "name": "Diyafah",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": schedules[i]['address_line1'] + " " + schedules[i]['address_line1'],
            "addressLocality": schedules[i]['city'],
            "postalCode": schedules[i]['zip_code'],
            "addressRegion": schedules[i]['address_region_code'],
            "addressCountry": schedules[i]['address_country_code']
          }
        }
        event_attendance = "https://schema.org/OfflineEventAttendanceMode";
      }
      allschedules_schema.push({
        "@context": "https://schema.org",
        "@type": "EducationEvent",
        "name": schedules[i].schedule_category=='2'?schedules[i]['course_title']:schedules[i]['package_name'],
        "description": schedules[i].schedule_category=='2'?schedules[i]['course_short_description']:schedules[i]['package_short_description'],
        "performer": {
          "@type": "Person",
          "name": schedules[i]['faculty_name']
        },
        //"image": schedules[i]['image'],
        "eventAttendanceMode": event_attendance,
        "eventStatus": "https://schema.org/EventScheduled",
        "location": location,
        "offers": {
          "@type": "AggregateOffer",
          "lowPrice": schedules[i]['course_offer_fee'],
          "url": this.domainurl+""+this.router.url,
          "availability": "https://schema.org/InStock",
          "validFrom": schedules[i]['offer_validty'],
          "price": schedules[i]['course_fee'],
          "priceCurrency": schedules[i]['currency_format']
        },
        "startDate": moment(schedules[i]['course_start_date']).format('YYYY-MM-DD'),
        "endDate": moment(schedules[i]['course_end_date']).format('YYYY-MM-DD'),
        "url": this.domainurl+""+this.router.url,
        "duration": schedules[i]['course_duration'],
        "organizer": {
          "@type": "Organization",
          "name": "Diyafah",
          "url":  this.domainurl
        }
      })
      if (Number(i) == schedules.length - 1) {
        return of(allschedules_schema);
      }
    }

  }
}
