import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeomoduleComponent } from './seomodule.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
 import {PipeModule} from '../../utils/pipes/pipe.module';
import {MoveToHeadDirective} from  '../../utils/directives/appMoveToHead';
@NgModule({
  declarations: [
    SeomoduleComponent,
    MoveToHeadDirective
  ],
  exports:[
    SeomoduleComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
 PipeModule
  ]
})
export class SeomoduleModule { }
