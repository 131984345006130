
import { IvyParser } from '@angular/compiler';
import { Component,OnInit,  TemplateRef,  ViewChild,  ElementRef,  EventEmitter,  ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationStart, NavigationCancel, Router } from '@angular/router';
declare var document: any;
declare let $: any, jQuery: any;
import { BsModalService, BsModalRef, ModalDirective, } from 'ngx-bootstrap/modal';
import { CommonService } from './../../utils/common.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('portal', { static: true }) portal: ElementRef;
  @ViewChild('mportal', { static: true }) mportal: ElementRef;
    @ViewChild('mportal1', { static: true }) mportal1: ElementRef;
    @ViewChild('select') select: NgSelectComponent;

  private subject: Subject<string> = new Subject();
  modalRef: BsModalRef;
  public search_term = new FormControl(null);
  public submenu: any = [];
  public subsubmenu: any = [];
  public active_menu = 0;
  public marque_text='';
  public web_search_results = [];
  public searchForm: FormGroup;
    public more_results=0;
  typeahead = new EventEmitter<string>();
  public loadsubmenu(iva: any) {
    this.active_menu = iva;
    this.subsubmenu = [];
    this.submenu = this.cs.nav_menu[iva]['children']
      ? this.cs.nav_menu[iva]['children']
      : [];
  }
  public loadsubsubmenu(iva: any) {
    this.subsubmenu = this.cs.nav_menu[this.active_menu]['children'][iva][
      'children'
    ]
      ? this.cs.nav_menu[this.active_menu]['children'][iva]['children']
      : [];
  }


  constructor( public router: Router,
    private modalService: BsModalService,
    public cs: CommonService,
    private formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
    ) {

      this.typeahead
      .pipe(
        debounceTime(500),
        switchMap((term) =>{
          this.web_search_results = [];
          this.cs.search_term=term;
          return this.cs.postData({
            sourceid: 'list/calldbproc',
            info: {
              procname: 'web_get_search_data',
              vals: [term, 3, 0],
            },
          })
        }

        )
      )
      .subscribe(
        (data) => {
          this.web_search_results = data.data['0'];
          this.more_results= data.data['1'][0]['rowcount'];
          if(this.web_search_results.length>0 && this.more_results>this.web_search_results.length){
            this.web_search_results.push({id:'1000',slug:'search',page_name:'View More',category_name:''})
          }
          this.cd.markForCheck();
        },
        (err) => {
          console.log('error', err);
          this.web_search_results = [];
          this.cd.markForCheck();
        }
      );
  }
  triggerenquiry(){
    $('#enquireid').click();
  }
  navigatefromsearch(type=null) {

     var localterm=this.search_term.value;
     var data=this.web_search_results.filter(item=> item.slug==localterm )[0];
     this.web_search_results = [];
     this.search_term.reset(null);
     if(type==1){
       this.cs.openmenu(data,1);
    }else{
      this.cs.openmenu(data,0);
    }

  }
  // navigatefromsearch(data) {
  //   if(this.cs.page_catids.indexOf(data['page_cat_id'])>-1){
  //     this.router.navigate(['/',this.cs.page_cats[data['page_cat_id']]]);
  //   }else if(data['parent_id']){
  //     this.router.navigate(['/',data['parent_slug']]);
  //   }else{
  //     this.router.navigate(['/',data.slug]);
  //   }

  // }
  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    //console.log('search term==', term);
    return [];
  }

  onKeyUp(searchTextValue: string) {
    this.subject.next(searchTextValue);
  }
  gotsearch(){

    this.router.navigate(['/search']);
  }
  ngOnInit(): void {

    this.cs
    .postData({
      sourceid: 'list/calldbproc',
      info: { db: 'cms', procname: 'getNotificationData', vals: [] },
    })
    .subscribe((data) => {
      if (data['status'] == 1) {
        this.marque_text = data['data'][0][0]['block_content'];
        console.log(this.marque_text,'==')
      }else{

      }
    });
    this.cs
      .postData({ sourceid: 'cms/site-data', info: { company_id: 1 } })
      .subscribe((data) => {
        if (data['status'] == 1) {
          this.cs.common_page_blocks=data.page_blocks;
        // console.log(JSON.stringify( this.cs.common_page_blocks));
          for (var i in this.cs.common_page_blocks) {
            try {
              if(this.cs.common_page_blocks[i]['block_id']=='portal'){
                this[
                  "m"+this.cs.common_page_blocks[i]['block_id']
                ].nativeElement.innerHTML =  this.cs.common_page_blocks[i][
                  'block_content'
                ];
                this[
                  "m"+this.cs.common_page_blocks[i]['block_id']+"1"
                ].nativeElement.innerHTML =  this.cs.common_page_blocks[i][
                  'block_content'
                ];
              }
              this[
                this.cs.common_page_blocks[i]['block_id']
              ].nativeElement.innerHTML =  this.cs.common_page_blocks[i][
                'block_content'
              ];
            } catch (error) {}
          }
        }
      });

    this.triggerquery();
  }
    triggerquery() {
      if (isPlatformBrowser(this.platformId)) {
      $(window).on('scroll', function() {
       if ($(this).scrollTop() > 300) {
           $('header').addClass("sticky");
           $('.inner-header').addClass("sticky-block");
           $('.internal-block').addClass("sticky-block");
       } else {
           $('header').removeClass("sticky");
           $('.inner-header').removeClass("sticky-block");
           $('.internal-block').removeClass("sticky-block");
       }
    });
    }
  }


}
