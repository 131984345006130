import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '',  loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule)},
   {path: 'home',  loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), pathMatch: 'full'},
   {path: 'enquirenow',  loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), pathMatch: 'full'},
   {path: 'bookaschooltour',  loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), pathMatch: 'full'},
   {path: 'contactus',  loadChildren: () => import('./components/pages/contactus/contactus.module').then(m => m.ContactusModule)},
   {path: 'admission-enquiry',  loadChildren: () => import('./components/pages/admissionenquiry/admissionenquiry.module').then(m => m.AdmissionenquiryModule)},
   //{path: 'our-staff',  loadChildren: () => import('./components/pages/staff/staff.module').then(m => m.StaffModule)},
   {path: 'school-events',    loadChildren: () => import('./components/pages/events/events.module').then(m => m.EventsModule)},
   {path: 'diyafah-in-the-news',  loadChildren: () => import('./components/pages/diyafahnews/diyafahnews.module').then(m => m.DiyafahnewsModule)},
   {path: 'blog',    loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule)},
   //{path: 'gallery',  loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule)},
  //  {path: 'enquirenow',  loadChildren: () => import('./components/pages/enquirenow.module').then(m => m.EnquirenowModule)},
   {path: 'faqs',  loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule)},
   {path: 'curriculum',  loadChildren: () => import('./components/pages/curriculum/curriculum.module').then(m => m.CurriculumModule)},
   {path: 'search',  loadChildren: () => import('./components/pages/search/search.module').then(m => m.SearchModule)},
   {path: 'sitemap',  loadChildren: () => import('./components/pages/sitemap/sitemap.module').then(m => m.SitemapModule)},
   {path: 'uniform',  loadChildren: () => import('./components/pages/uniform/uniform.module').then(m => m.UniformModule)},
   { path: 'enquire-now', loadChildren: () => import('./components/pages/enquirenow/enquirenow.module').then(m => m.EnquirenowModule) },

   {path: 'error',  loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule)},
   {path: ':slug',  loadChildren: () => import('./components/pages/infopages/infopages.module').then(m => m.InfopagesModule)},
    { path: '**',redirectTo: 'error/404' },
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
