// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  PHASEPRASE: 'ZW5hMjBwamgxNToyNmJhZjBjZjQ5Y2Q4ZmM4NGE5NGYxMmVhNGJiM2NmZA==',
  sevurl:'https://static.diyafahinternationalschool.com:4192/',
  host:'https://static.diyafahinternationalschool.com:4192/',
//  sevurl:'https://static.diyafahinternationalschool.com:4195/',
//  host:'https://static.diyafahinternationalschool.com:4195/',
  //sevurl: 'http://localhost:4192/',
  // host: 'http://localhost:4192/',
  application: {
    name: 'diyafahinternationalschool',
  },
  domain: 'https://diyafahinternationalschool.com/',
  ggglcy: '6Lc2mw0UAAAAALPK226A_wBVU2J8QKrMhamLgWTf',
  imagealt:
    'Best British Curriculum School in Abu Dhabi | Top International school In UAE | diyafahinternationalschool.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import sy
 * yould be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
